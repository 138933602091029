export const SSO = {
  client_id: process.env.REACT_APP_SSO_CLIENT_ID,
  redirect_uri: `${window.location.origin}/sso/oauth`,
  domain:
    process.env.REACT_APP_ENV === "prod"
      ? "https://sso-gamingtec.com"
      : "https://stage.sso-gamingtec.com",
  widget:
    process.env.REACT_APP_ENV === "prod"
      ? "https://widget.sso-gamingtec.com/index.js"
      : "https://widget-stage.sso-gamingtec.com/index.js",
} as const;
