import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { SSO } from "./constants";
import { useDispatch } from "react-redux";
import { logoutAction } from "../actions";
import { history } from "../../../system/store";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";

export const SsoWidget = () => {
  const dispatch = useDispatch();

  const loadSSOWidget = () => {
    try {
      return new Promise(function (resolve, reject) {
        const isAlreadyAdded = document.getElementById("sso-widget-script");
        if (isAlreadyAdded) return;

        const script = document.createElement("script");
        script.src = `${SSO.widget}?timestamp=${Date.now()}`;
        script.async = true;
        script.id = "sso-widget-script";
        script.onload = function () {
          const w: any = window;
          if (typeof w.initWidget === "function") {
            w.initWidget({
              rootSelector: "#sso-widget",
              token: localStorage.getItem("sso_token"),
              baseApiUrl: SSO.domain,
              onLogout: () => {
                fetch(`${SSO.domain}/api/logout`, {
                  method: "POST",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "sso_token"
                    )}`,
                  },
                }).then(() => {
                  dispatch(logoutAction());
                  window.location.href = `${SSO.domain}/login`;
                });
              },
              onError: (e: any) => {
                if (e.status === 401) {
                  history.push(APP_ROUTES.sso.access.path);
                }
              },
            });
          }
        };
        script.onerror = function () {
          reject("SSO Widget: Failed to load the script");
        };
        document.head.appendChild(script);
      });
    } catch (err) {
      console.error("===", "error");
    }
  };

  useEffect(() => {
    loadSSOWidget();
  });
  return <div id="sso-widget"></div>;
};

export default SsoWidget;
