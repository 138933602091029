import React, { useEffect } from "react";
import { requestSsoCode } from "./utils";

const PageSsoAccess = () => {
  let isRequested = false;
  useEffect(() => {
    if (!isRequested) {
      requestSsoCode();
      isRequested = true;
    }
  }, []);
  return <div></div>;
};

export default PageSsoAccess;
