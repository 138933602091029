import React from "react";
import { useGetAuthorized } from "./utils";

export const PageOAuth = () => {
  const { data, isFetching, isError } = useGetAuthorized();
  return (
    <div className="page-oauth">
      {!data && isFetching && <>...Processing authentication</>}
      {isError && <p>Unable to get authorized session</p>}
      {data && !isError && <p>...Redirecting to the Dashboard</p>}
    </div>
  );
};

export default PageOAuth;
