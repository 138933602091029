import CryptoJS from "crypto-js";
import { SSO } from "./constants";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserBasic } from "../../User/actions";
import {
  getItem,
  loginHelper,
} from "../../../system/helpers/localstorageHelper";
import { history } from "../../../system/store";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";

export const codeGenerating = () => {
  function generateRandomString(length: number) {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_.~";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      randomString += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return randomString;
  }

  const code_verifier = generateRandomString(128);
  const code_challenge = CryptoJS.SHA256(code_verifier)
    .toString(CryptoJS.enc.Base64)
    .replace(/=+$/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");

  localStorage.setItem("code_verifier", code_verifier);
  localStorage.setItem("code_challenge", code_challenge);
  return { code_verifier, code_challenge };
};

export const requestSsoCode = () => {
  const { code_challenge } = codeGenerating();
  const params = {
    client_id: SSO.client_id,
    redirect_uri: SSO.redirect_uri,
    response_type: "code",
    code_challenge: code_challenge,
    code_challenge_method: "S256",
    state: "1",
  };
  window.location.href = `${SSO.domain}/oauth/authorize?${new URLSearchParams(
    params
  ).toString()}`;
};

export const useGetAuthorized = () => {
  const dispatch = useDispatch();
  const params = new URL(window.location.href).searchParams;

  const request: any = useQuery({
    queryKey: ["request-sso-code", params.get("code")],
    queryFn: async () => {
      const response = await fetch(`${SSO.domain}/api/oauth/tokens`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          grant_type: "authorization_code",
          client_id: SSO.client_id,
          redirect_uri: SSO.redirect_uri,
          code_verifier: localStorage.getItem("code_verifier"),
          code: params.get("code"),
        }),
      });
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error();
      }
    },
  });

  useEffect(() => {
    if (request.data && !request.isLoading) {
      loginHelper(request.data.external);

      console.log("===", request.data);
      console.log("===", request.data.external.user_id);
      dispatch(getUserBasic({ user_id: request.data.external.user_id }));
      localStorage.setItem("sso_token", request.data?.internal.access_token);

      getItem("role") === "admin"
        ? history.push(APP_ROUTES.dashboard.admin)
        : history.push(APP_ROUTES.dashboard.general);
    }
  }, [request.data, request.isLoading]);

  return request;
};
